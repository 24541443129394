<div class="bulk-load-container">
  <app-page-header
    [title]="'Boletas'"
    [subtitle]="'Administra tus boletas y facturas'"
  ></app-page-header>

  <div class="card">
    <div class="column">
      <div class="row">
        <h2 class="label">
          <button type="button" pButton (click)="goBack()" class="btn-back">
            <i class="pi pi-arrow-circle-left"></i>
          </button>
          Carga masiva de boletas y facturas
        </h2>
      </div>

      <div class="row content" *ngIf="currentStep === 1">
        <div class="column">
          <div class="field">
            <div
              class="file-container"
              (dragover)="onDragOver($event)"
              (dragleave)="onDragLeave($event)"
              (drop)="onDrop($event)"
            >
              <div class="file-preview" (click)="openFileUpload('xml')">
                <div class="custom-file-display">
                  <div class="custom-file-top">
                    <i
                      [ngClass]="
                        selectedFileNames && selectedFileNames.length > 0
                          ? ''
                          : 'pi pi-cloud-upload'
                      "
                    ></i>
                  </div>
                  <div class="file-names-container">
                    <span
                      *ngIf="
                        selectedFileNames && selectedFileNames.length > 0;
                        else noFiles
                      "
                    >
                      <ul class="file-list">
                        <li
                          *ngFor="
                            let fileName of selectedFileNames;
                            let i = index
                          "
                        >
                          <i class="pi pi-file-excel"></i>{{ fileName }}
                          <i class="pi pi-times" (click)="removeFile(i)"></i>
                        </li>
                      </ul>
                    </span>
                    <ng-template #noFiles>
                      <span
                        >Haga clic para cargar o arrastre y suelte hasta 25
                        archivos XML</span
                      >
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>

            <div
              style="
                color: grey;
                font-size: 0.75rem;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
              "
            >
              <p style="padding: 0; margin-top: 0.2rem">
                Formatos compatibles: XML
              </p>
              <p style="padding: 0; margin-top: 0.2rem">
                Max. Tamaño por archivo: 25 MB
              </p>
            </div>

            <p-fileUpload
              #xmlUploader
              [multiple]="true"
              [accept]="'.xml, application/xml, text/xml'"
              [maxFileSize]="25000000"
              (onSelect)="onChangeFile($event)"
              [style]="{ display: 'none' }"
            ></p-fileUpload>
          </div>
        </div>

        <div class="column">
          <div class="field">
            <label for="company" class="font-semibold">Empresa</label>
            <p-autoComplete
              id="company"
              [(ngModel)]="selectedCompany"
              [suggestions]="filteredOptions"
              (completeMethod)="filterOptions($event, 'company')"
              (input)="onChangeInput($event, 'company')"
              [dropdown]="true"
              [forceSelection]="true"
              [placeholder]="'Selecciona una empresa'"
              (onSelect)="onSelect($event, 'company')"
              [style]="{ width: '100%' }"
              [field]="'text'"
            ></p-autoComplete>
          </div>

          <label for="period" class="font-semibold">Periodo</label>
          <div class="field-container">
            <div class="field">
              <p-autoComplete
                id="year"
                [(ngModel)]="selectedYear"
                [suggestions]="filteredOptions"
                (completeMethod)="filterOptions($event, 'year')"
                (input)="onChangeInput($event, 'year')"
                [dropdown]="true"
                [forceSelection]="true"
                [placeholder]="'Año'"
                (onSelect)="onSelect($event, 'year')"
                [style]="{ width: '100%' }"
                [field]="'text'"
              ></p-autoComplete>
            </div>
            <div class="field">
              <p-autoComplete
                id="month"
                [(ngModel)]="selectedMonth"
                [suggestions]="filteredOptions"
                (completeMethod)="filterOptions($event, 'month')"
                (input)="onChangeInput($event, 'month')"
                [dropdown]="true"
                [forceSelection]="true"
                [placeholder]="'Mes'"
                (onSelect)="onSelect($event, 'month')"
                [style]="{ width: '100%' }"
                [field]="'text'"
              ></p-autoComplete>
            </div>
          </div>

          <div class="field">
            <label for="typeProofPayment" class="font-semibold"
              >Tipo de Comprobante de Pago</label
            >
            <p-autoComplete
              id="typeProofPayment"
              [(ngModel)]="selectedTypeProofPayment"
              [suggestions]="filteredOptions"
              (completeMethod)="filterOptions($event, 'typeProofPayment')"
              (input)="onChangeInput($event, 'typeProofPayment')"
              [dropdown]="true"
              [forceSelection]="true"
              [placeholder]="'Selecciona un comprobante de pago'"
              (onSelect)="onSelect($event, 'typeProofPayment')"
              [style]="{ width: '100%' }"
              [field]="'text'"
            ></p-autoComplete>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="currentStep === 2">
        <div class="column">
          <div class="field-correlative">
            <label for="correlative" class="font-semibold"
              >Siguiente correlativo</label
            >
            <div class="field-container">
              <input
                pInputText
                id="serie"
                type="text"
                [(ngModel)]="serie"
                (ngModelChange)="onChangeInput($event, 'serie')"
                [disabled]="true"
              />
              <input
                id="correlative"
                type="text"
                pInputText
                [(ngModel)]="correlative"
                (ngModelChange)="onChangeInput($event, 'correlative')"
                placeholder="Número correlativo"
                [disabled]="true"
              />

              <button
                type="button"
                pButton
                label="Obtener"
                (click)="backStep()"
                class="btn-correlative"
              ></button>
            </div>
          </div>

          <!-- Tabs y tabla dinámica -->
          <app-page-container
            [ngStyle]="{ 'font-size': '0.8rem' }"
            [displayedColumns]="displayedColumns"
            [dataSource]="dataSource"
            [showPreviewCard]="false"
            [isLoading]="isLoading"
            [hasCheckbox]="false"
            [customTemplates]="{
              nombreTrabajador: nombreTrabajadorTemplate,
              tipoTrabajador: tipoTrabajadorTemplate,
              situacionLaboral: situacionLaboralTemplate,
              email: emailTemplate,
              regimenPensionario: registimenPensionarioTemplate
            }"
          >
            <ng-template #nombreTrabajadorTemplate let-row>
              <span class="truncate-text">{{
                row.cabecera?.nombreTrabajador
                  ? (row.cabecera.nombreTrabajador | capitalize)
                  : ""
              }}</span>
            </ng-template>

            <ng-template #tipoTrabajadorTemplate let-row>
              {{
                row.cabecera?.tipoTrabajador
                  ? (row.cabecera.tipoTrabajador | capitalize)
                  : ""
              }}
            </ng-template>

            <ng-template #emailTemplate let-row>
              <span class="truncate-text">{{ row.cabecera?.email }}</span>
            </ng-template>

            <ng-template #situacionLaboralTemplate let-row>
              <span class="truncate-text-sit">{{
                row.cabecera?.situacionLaboral
                  ? (row.cabecera.situacionLaboral | capitalize)
                  : ""
              }}</span>
            </ng-template>

            <ng-template #registimenPensionarioTemplate let-row>
              <span class="truncate-text-minor">{{
                row.cabecera?.regimenPensionario
              }}</span>
            </ng-template>
          </app-page-container>
        </div>
      </div>

      <div class="row">
        <div class="column">
          <div style="display: flex; justify-content: center; width: 100%">
            <button
              type="button"
              pButton
              label="Cancelar"
              (click)="backStep()"
              class="cancel-button"
            ></button>
          </div>
        </div>

        <div class="column">
          <div style="display: flex; justify-content: center; width: 100%">
            <button
              type="button"
              pButton
              [label]="nextButtonLabel"
              (click)="nextStep()"
              class="next-button"
              [disabled]="!isFormValid()"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

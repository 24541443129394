import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaymentSlipsComponent } from './payment-slips.component';
import { BulkLoadComponent } from './components/bulk-load/bulk-load.component';

const routes: Routes = [
  {
    path: '',
    component: PaymentSlipsComponent,
  },
  {
    path: 'bulk-load',
    component: BulkLoadComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PaymentSlipsRoutingModule {}

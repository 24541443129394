import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { monthOptions } from '../utils/helpers';
import { PaymentSlipService } from './services/payment-slip.service';
import { PaymentSlipResponseDTO } from '../interfaces/payment-slips.interface';
import { CompanyService } from '../services/company.service';
import { IComboBase } from '../interfaces/base/base.interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-slips',
  templateUrl: './payment-slips.component.html',
  styleUrls: ['./payment-slips.component.scss'],
})
export class PaymentSlipsComponent {
  dataSource: PaymentSlipResponseDTO[] = [];
  mostrar: boolean = false;
  dataToEdit: any = null;
  mostrarModalEditar: boolean = false;
  mostrarModalDetalle: boolean = false;
  dataDetail: any = null;

  isGeneratingPDF: boolean = false;

  selectedRow: any = null;
  // Opciones del combo
  monthOptions: IComboBase[] = [{ key: '00', text: 'Todos' }, ...monthOptions];
  currentMonth: IComboBase | null = null;

  isLoading: boolean = true;

  // Término de búsqueda
  searchTerm = '';

  // Mes seleccionado
  selectedMonth: IComboBase | null = null;
  userData: any = null;

  headerClasses: { [key: string]: string } = {
    '1': 'header-keola',
    '2': 'header-inresort',
    '3': 'header-inclub',
    '4': 'header-ribera',
    '5': 'header-keolafood',
    '6': 'header-valle',
  };

  currentHeaderClass: string = 'header-keola';

  selectedDocument: SafeResourceUrl | null = null;

  @Output() rowSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    private paymentSlipService: PaymentSlipService,
    private companyService: CompanyService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.currentMonth = this.getCurrentMonth();
    this.selectedMonth = this.currentMonth;
  }

  tabs: IComboBase[] = [];
  selectedTabKey: string | null = null;

  displayedColumns = [
    { key: 'paymentDocumentNumber', header: 'Nº Documento' },
    { key: 'paymentSlipAmount', header: 'Importe' },
    { key: 'issueDate', header: 'Fecha' },
    { key: 'employeeName', header: 'Para el empleado' },
    { key: 'employeeDocumentNumber', header: 'D.N.I.' },
  ];

  actionButtons = [
    {
      icon: 'pi pi-pen-to-square',
      color: '#ED672A',
      tooltip: 'Editar',
      action: (row: any) => {
        this.dataToEdit = {
          ...row,
          empresa: this.tabs.find((tab) => tab.key === this.selectedTabKey),
        };
        this.mostrarModalEditar = true;
      },
      visible: (row: any) => row.isManual ==='No' ? false : true,
    },
    {
      icon: 'pi pi-eye',
      color: '#ED672A',
      tooltip: 'Ver',
      action: (row: any) => {
        this.dataDetail = {
          ...row,
          empresa: this.tabs.find((tab) => tab.key === this.selectedTabKey),
        };
        this.mostrarModalDetalle = true;
      },
      visible: (row: any) => true,
    },
  ];

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }
    this.companyService.comboCompany().subscribe({
      next: (data) => {
        this.tabs = data;
        this.selectedTabKey = this.tabs[0].key;
        this.onTabSelected(this.selectedTabKey);
      },
      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
        this.filterData();
      },
    });
  }

  onNewRegister(): void {
    this.mostrar = true;
  }

  onBulkLoad(): void {
    this.router.navigate(['/dashboard/payment-slips/bulk-load']);
  }

  onDialogClose() {
    this.mostrar = false; // Cierra el modal
  }

  onDialogDetailClose() {
    this.mostrarModalDetalle = false;
    this.dataDetail = null;
  }

  onMonthChange(selected: IComboBase): void {
    this.selectedMonth = selected;
    this.filterData(); // Aplicar filtro cuando cambia el mes
  }

  private getCurrentMonth(): IComboBase | null {
    const currentMonthKey = new Date().toLocaleDateString('en-GB', {
      month: '2-digit',
    });
    return (
      this.monthOptions.find((option) => option.key === currentMonthKey) || null
    );
  }

  onSearch(query: string): void {
    this.searchTerm = query;
    this.filterData(); // Aplicar filtro cuando cambia la búsqueda
  }

  filterData(): void {
    this.isLoading = true;
    const filters = {
      search: this.searchTerm,
      month: this.selectedMonth?.key == '00' ? null : this.selectedMonth?.key, // Si no hay mes seleccionado, enviar null
      companyId: this.selectedTabKey || null,
    };

    this.paymentSlipService.getAllPaymentSlips(filters).subscribe({
      next: (data: PaymentSlipResponseDTO[]) => {
        data.sort((a, b) => a.employeeName.localeCompare(b.employeeName));
        this.dataSource = data;
        this.isLoading = false;
      },
      error: (err: any) => {
        this.isLoading = false;
        console.error('Error al obtener los datos:', err);
      },
    });
  }

  onTabSelected(tabKey: string): void {
    this.selectedTabKey = tabKey;
    this.currentHeaderClass = this.headerClasses[tabKey] || 'header-keola';
    this.selectedDocument = null;
    this.filterData();
    // Puedes agregar lógica adicional aquí, como actualizar los datos mostrados
  }

  generatePDF() {
    if (this.selectedRow && this.selectedRow.paymentSlipId) {
      const paymentSlipId = this.selectedRow.paymentSlipId;
      this.isGeneratingPDF = true;

      this.paymentSlipService.getGeneratePDF(paymentSlipId, this.userData.employeeId).subscribe({
        next: (response) => {
          this.filterData();

          setTimeout(() => {
            const updatedRow = this.dataSource.find(
              (row) => row.paymentSlipId === paymentSlipId
            );

            if (updatedRow) {
              this.selectedRow = updatedRow;

              this.onRowSelected(updatedRow);
              this.rowSelected.emit(updatedRow);

              setTimeout(() => {
                this.selectedDocument = null;

                setTimeout(() => {
                  this.selectedDocument =
                    this.sanitizer.bypassSecurityTrustResourceUrl(
                      this.selectedRow.paymentSlipUrl
                    );

                  this.isGeneratingPDF = false;

                  this.cdr.detectChanges();
                }, 100);
              }, 0);
            } else {
              console.warn('No se encontró la fila actualizada en dataSource');
            }
          }, 1000);
        },
        error: (error) => {
          console.error('Error generando boleta:', error);
          this.isGeneratingPDF = false;
        },
      });
    } else {
      console.warn('No hay fila seleccionada o no tiene paymentSlipId');
    }
  }

  updateSelectedDocument(url: string) {
    this.selectedDocument = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.cdr.detectChanges();
  }

  // Método para manejar la selección de la fila
  onRowSelected(row: any): void {
    this.selectedRow = row;

    if (row && row.paymentSlipUrl) {
      const uniqueUrl = `${
        row.paymentSlipUrl
      }?timestamp=${new Date().getTime()}`;
      this.selectedDocument =
        this.sanitizer.bypassSecurityTrustResourceUrl(uniqueUrl);
    } else {
      this.selectedDocument = null;
    }
    this.cdr.detectChanges();
  }

  onRegisterComplete(): void {
    this.filterData();
  }

  onEditComplete(): void {
    this.filterData();
  }

  onDeleteComplete(): void {
    this.filterData();
  }
}

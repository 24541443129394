<div class="boletas-container">
  <!-- Título y subtítulo -->
  <app-page-header
    [title]="'Boletas'"
    [subtitle]="'Administra tus boletas y facturas'"
  ></app-page-header>

  <!-- Botones, combo y barra de búsqueda -->
  <app-page-acciones
    [buttonText]="'Agregar boleta'"
    [buttonIcon]="'pi pi-plus'"
    [buttonColor]="'white'"
    [textButtonColor]="'#04a563'"
    (buttonClick)="onNewRegister()"
    [buttonTextSecond]="'Carga masiva'"
    [buttonIconSecond]="'pi pi-upload'"
    [buttonColorSecond]="'#04a563'"
    [textSecondButtonColor]="'white'"
    (buttonClickSecond)="onBulkLoad()"
    [hasButtonSecond]="true"
    [comboOptions]="monthOptions"
    [comboSelected]="currentMonth"
    (comboSelectedChange)="onMonthChange($event)"
    [searchQuery]="searchTerm"
    (searchQueryChange)="onSearch($event)"
  >
  </app-page-acciones>

  <!-- Tabs y tabla dinámica -->
  <app-page-container
    [tabs]="tabs"
    [displayedColumns]="displayedColumns"
    [dataSource]="dataSource"
    [actionButtons]="actionButtons"
    [headerClass]="currentHeaderClass"
    [showPreviewCard]="true"
    [showGenerateButton]="true"
    [generateButtonAction]="generatePDF.bind(this)"
    [isLoading]="isLoading"
    [isGeneratingPDF]="isGeneratingPDF"
    [customTemplates]="{
      paymentSlipAmount: paymentSlipAmountTemplate,
      employeeName: employeeNameTemplate
    }"
    (tabSelected)="onTabSelected($event)"
    (rowSelected)="onRowSelected($event)"
    [selectedRow]="selectedRow"
    [selectedDocument]="selectedDocument"
    [hasCheckbox]="true"
  >
    <ng-template #paymentSlipAmountTemplate let-row>
      {{ row.paymentSlipAmount | currencySymbol : row.currencyId }}
    </ng-template>
    <ng-template #employeeNameTemplate let-row>
      {{ row.employeeName | capitalize }}
    </ng-template>
  </app-page-container>

  <!-- Modal del registro -->
  <app-registro-modal
    [(visible)]="mostrar"
    (visibleChange)="onDialogClose()"
    (registerComplete)="onRegisterComplete()"
  ></app-registro-modal>

  <app-edit-modal
    [(visible)]="mostrarModalEditar"
    [dataToEdit]="dataToEdit"
    (visibleChange)="onDialogClose()"
    (editComplete)="onEditComplete()"
    (deleteComplete)="onDeleteComplete()"
  ></app-edit-modal>

  <app-detail-modal
    [(visible)]="mostrarModalDetalle"
    [dataToView]="dataDetail"
    (visibleChange)="onDialogDetailClose()"
  ></app-detail-modal>
</div>

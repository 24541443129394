import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PaymentSlipResponseDTO } from '../../interfaces/payment-slips.interface';

@Injectable({
  providedIn: 'root',
})
export class PaymentSlipService {
  private apiUrl = `${environment.apiUrl}/paymentSlips`;

  constructor(private http: HttpClient) {}

  getPaymentSlipById(id: number): Observable<any> { 
    return this.http.get<any>(`${this.apiUrl}/detailPaymentSlipById/${id}`);
  }  

  getAllPaymentSlips(filter: { search: string }): Observable<PaymentSlipResponseDTO[]> {
    return this.http.post<PaymentSlipResponseDTO[]>(this.apiUrl + '/listPaymentSlipByCompany', filter);
  }

  updatePaymentSlip(paymentSlipId: number, data: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/updatePaymentSlip/${paymentSlipId}`, data);
  }
  
  createPaymentSlip(data: FormData): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/registerPaymentSlip', data);
  }

  deletePaymentSlip(id: number): Observable<any> {
    return this.http.delete<any>(`${this.apiUrl}/deletePaymentSlip/${id}`);
  }

  postReadPaymentSlipXml(data: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}Xml/process`, data);
  }

  getGeneratePDF(paymentSlipId: number, userId: number): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}Xml/generatorPDF/${paymentSlipId}?userId=${userId}`);
  }

  postRegisterBulkLoad(data: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}Xml/registerPaymentSlip`, data, {
      headers: { "Content-Type": "application/json" }
    });
  }

  updateStatePaymentSlip(idPaymentSlip: number, data: FormData) : Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/changeStatePaymentSlip/${idPaymentSlip}`, data);
  }
}

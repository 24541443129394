<p-dialog
  header="Editar Registro"
  [(visible)]="visible"
  [modal]="true"
  (onHide)="closeDialog()"
  [closable]="true"
  [style]="{ width: '30%', height: '100vh' }"
>
  <div class="loading-spinner" *ngIf="isLoading">
    <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
  </div>
  <div class="flex flex-column gap-3 mb-3" *ngIf="!isLoading">
    <!-- Empresa -->
    <div class="field">
      <label for="empresa" class="font-semibold">Empresa</label>
      <p-autoComplete
        id="empresa"
        [(ngModel)]="selectedEmpresa"
        [suggestions]="filteredOptions"
        (completeMethod)="filterOptions($event, 'empresa')"
        (input)="onChangeInput($event, 'empresa')"
        [dropdown]="true"
        [forceSelection]="true"
        [placeholder]="'Selecciona una empresa...'"
        (onSelect)="onSelect($event, 'empresa')"
        [style]="{ width: '100%' }"
        [field]="'text'"
      ></p-autoComplete>
      <div
        *ngIf="!selectedEmpresa && hasInteracted['empresa']"
        class="error-message"
        style="color: red; font-size: 0.875rem; margin-top: 5px"
      >
        Este campo es obligatorio.
      </div>
    </div>

    <!-- Colaborador -->
    <div class="field">
      <label for="colaborador" class="font-semibold">Colaborador</label>
      <p-autoComplete
        id="colaborador"
        [(ngModel)]="selectedColaborador"
        [suggestions]="filteredOptions"
        (completeMethod)="filterOptions($event, 'colaborador')"
        (input)="onChangeInput($event, 'colaborador')"
        [dropdown]="true"
        [forceSelection]="true"
        [placeholder]="'Selecciona un colaborador...'"
        (onSelect)="onSelect($event, 'colaborador')"
        [style]="{ width: '100%' }"
        [field]="'text'"
      ></p-autoComplete>
      <div
        *ngIf="!selectedColaborador && hasInteracted['colaborador']"
        class="error-message"
        style="color: red; font-size: 0.875rem; margin-top: 5px"
      >
        Este campo es obligatorio.
      </div>
    </div>

    <!-- Moneda -->
    <div class="field">
      <label for="moneda" class="font-semibold">Moneda</label>
      <p-autoComplete
        id="moneda"
        [(ngModel)]="selectedMoneda"
        [suggestions]="filteredOptions"
        (completeMethod)="filterOptions($event, 'moneda')"
        (input)="onChangeInput($event, 'moneda')"
        [dropdown]="true"
        [forceSelection]="true"
        [placeholder]="'Selecciona una moneda...'"
        (onSelect)="onSelect($event, 'moneda')"
        [style]="{ width: '100%' }"
        [field]="'text'"
      ></p-autoComplete>
      <div
        *ngIf="!selectedMoneda && hasInteracted['moneda']"
        style="color: red; font-size: 0.875rem; margin-top: 5px"
      >
        Este campo es obligatorio.
      </div>
    </div>

    <!-- Monto -->
    <div class="field">
      <label for="monto" class="font-semibold">Monto</label>
      <br />
      <p-inputNumber
        id="monto"
        [(ngModel)]="monto"
        (ngModelChange)="onChangeInput($event, 'monto')"
        mode="decimal"
        [max]="100000"
        [maxFractionDigits]="2"
        [step]="1"
        [placeholder]="'Ingresa un monto...'"
        [style]="{ width: '100%' }"
      ></p-inputNumber>
      <div
        *ngIf="!monto && hasInteracted['monto']"
        style="color: red; font-size: 0.875rem; margin-top: 5px"
      >
        Este campo es obligatorio.
      </div>
    </div>

    <!-- Fecha -->
    <div class="field">
      <label for="fecha" class="font-semibold">Fecha</label>
      <p-calendar
        id="fecha"
        [(ngModel)]="issueDate"
        [showIcon]="true"
        dateFormat="dd/mm/yy"
        placeholder="Selecciona una fecha"
        [style]="{ width: '100%' }"
        (onSelect)="validateForm()"
        (onBlur)="validateField('fecha')"
      ></p-calendar>
      <div
        *ngIf="!issueDate && hasInteracted['fecha']"
        style="color: red; font-size: 0.875rem; margin-top: 5px"
      >
        Este campo es obligatorio.
      </div>
    </div>

    <!-- Switch -->
    <div class="switch-container">
      <label for="isPaid" class="font-semibold">¿Está pagado?</label>
      <p-inputSwitch id="isPaid" [(ngModel)]="isPaid"></p-inputSwitch>
    </div>

    <!-- Carga de archivo PDF -->
    <div class="field">
      <label for="documento" class="font-semibold"
        >Cargar Documento (PDF)</label
      >
      <div class="custom-file-upload" (click)="openFileUpload()">
        <p-fileUpload
          #fileUploader
          [accept]="'application/pdf'"
          [maxFileSize]="10000000"
          (onSelect)="onChangeFile($event)"
          [style]="{ display: 'none' }"
        ></p-fileUpload>
        <div class="custom-file-display">
          <span>{{ selectedFileName || "Selecciona un archivo..." }}</span>
          <i
            [ngClass]="
              selectedFileName ? 'pi pi-file-pdf' : 'pi pi-folder-open'
            "
          ></i>
        </div>
      </div>
      <div
        *ngIf="!currentFile && hasInteracted['documento']"
        style="color: red; font-size: 0.875rem; margin-top: 5px"
      >
        Este campo es obligatorio.
      </div>
    </div>
  </div>

  <div
    style="display: flex; justify-content: center; flex-direction: column"
    *ngIf="!isLoading"
  >
    <!-- Botón Aceptar -->
    <button
      type="button"
      pButton
      label="Guardar cambios"
      [disabled]="isDisabled"
      (click)="onEdit()"
      class="btn"
    ></button>

    <!-- Botón Eliminar -->
    <button
      type="button"
      pButton
      label="Eliminar"
      (click)="onDelete()"
      class="btn-delete"
    ></button>
  </div>
</p-dialog>

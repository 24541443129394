import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CompanyService } from '../../../services/company.service';
import { Router } from '@angular/router';
import { ModalRejectComponent } from '../../../shared/modals/modal-reject/modal-reject.component';
import { DialogService } from 'primeng/dynamicdialog';
import { IComboBase } from '../../../interfaces/base/base.interface';
import { PaymentSlipService } from '../../services/payment-slip.service';
import { getYearOptions, monthOptions } from '../../../utils/helpers';
import { ModalConfirmationComponent } from '../../../shared/modals/modal-confirmation/modal-confirmation.component';
import { ModalSuccessComponent } from '../../../shared/modals/modal-success/modal-success.component';
import { ModalLoadingComponent } from '../../../shared/modals/modal-loading/modal-loading.component';

@Component({
  selector: 'app-bulk-load',
  templateUrl: './bulk-load.component.html',
  styleUrls: ['./bulk-load.component.scss'],
  providers: [DialogService],
})
export class BulkLoadComponent {
  userData: any = null;
  dataSource: any[] = [];
  currentFiles: File[] = [];

  selectedCompany: IComboBase | null = null;
  selectedMonth: IComboBase | null = null;
  selectedYear: IComboBase | null = null;
  selectedTypeProofPayment: IComboBase | null = null;
  filteredOptions: IComboBase[] = [];
  companies: IComboBase[] = [];
  months: IComboBase[] = [];
  years: IComboBase[] = [];
  typeProofPayments: IComboBase[] = [];

  currentStep: number = 1;
  selectedFileNames: string[] = [];

  isLoading: boolean = true;
  serie: string | null = null;
  correlative: string | null = null;

  @ViewChild('xmlUploader') xmlUploader: any;

  displayedColumns = [
    { key: 'item', header: 'Item' },
    { key: 'cabecera.fechaIngreso', header: 'Ingreso' },
    { key: 'jobPositionName', header: 'Serie' },
    { key: 'cabecera.numeroDocumento', header: 'DNI' },
    { key: 'nombreTrabajador', header: 'Empleado' },
    { key: 'moneda', header: 'Moneda' },
    { key: 'cabecera.netoPagar', header: 'Importe' },
    { key: 'email', header: 'Correo' },
    { key: 'tipoTrabajador', header: 'Tipo' },
    { key: 'regimenPensionario', header: 'Regimen' },
    { key: 'situacionLaboral', header: 'Situación' },
    { key: 'cabecera.diasLaborados', header: 'Días lab.' },
    { key: 'cabecera.diasNoLaborados', header: 'Días no lab.' },
  ];

  constructor(
    private paymentSlipService: PaymentSlipService,
    private companyService: CompanyService,
    private dialogService: DialogService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const userDataString = localStorage.getItem('access');
    if (userDataString) {
      this.userData = JSON.parse(userDataString);
    }
    this.companyService.comboCompany().subscribe({
      next: (data) => {
        this.companies = data;
      },
      error: (err) => {
        console.error('Error al obtener la lista de empresas:', err);
      },
    });

    this.months = monthOptions;
    this.years = getYearOptions();
    this.typeProofPayments = [{ key: '01', text: 'Boleta' }];
  }

  openFileUpload(type: string) {
    if (type === 'xml' && this.xmlUploader) {
      this.xmlUploader.choose();
    } else {
      console.error('El fileUploader no está disponible.');
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer!.dropEffect = 'copy';
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      const droppedFiles = Array.from(event.dataTransfer.files);

      if (droppedFiles.length > 25) {
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'No se pueden cargar más de 25 archivos a la vez.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
        return;
      }

      const validFiles = droppedFiles.filter((file) => this.isValidFile(file));

      if (validFiles.length !== droppedFiles.length) {
        const ref = this.dialogService.open(ModalRejectComponent, {
          header: '',
          data: {
            text: 'Algunos archivos no son válidos. Solo se permiten archivos XML de hasta 25 MB.',
            title: '¡Upss!',
            showButton: true,
            buttonText: 'Entendido',
          },
        });
      }

      this.currentFiles = validFiles;
      this.selectedFileNames = validFiles.map((file) => file.name);
    } else {
      this.currentFiles = [];
      this.selectedFileNames = [];
    }
  }

  isValidFile(file: File): boolean {
    const allowedExtensions = ['application/xml', 'text/xml'];
    const maxSize = 25 * 1024 * 1024;

    if (!allowedExtensions.includes(file.type)) {
      console.log('Tipo de archivo no permitido:', file.type);
      return false;
    }

    if (!file.name.toLowerCase().endsWith('.xml')) {
      console.log('Extensión de archivo no permitida:', file.name);
      return false;
    }

    if (file.size > maxSize) {
      console.log('Archivo demasiado grande:', file.size);
      return false;
    }

    return true;
  }

  onChangeFile(event: any) {
    const selectedFiles = Array.from<File>(event.files);

    if (selectedFiles.length > 25) {
      const ref = this.dialogService.open(ModalRejectComponent, {
        header: '',
        data: {
          text: 'No se pueden cargar más de 25 archivos a la vez.',
          title: '¡Upss!',
          showButton: true,
          buttonText: 'Entendido',
        },
      });
      return;
    }

    const validFiles = selectedFiles.filter((file: File) =>
      this.isValidFile(file)
    );

    if (validFiles.length !== selectedFiles.length) {
      const ref = this.dialogService.open(ModalRejectComponent, {
        header: '',
        data: {
          text: 'Algunos archivos no son válidos. Solo se permiten archivos XML de hasta 25 MB.',
          title: '¡Upss!',
          showButton: true,
          buttonText: 'Entendido',
        },
      });
    }

    this.currentFiles = validFiles;
    this.selectedFileNames = validFiles.map((file: File) => file.name);
  }

  filterOptions(event: any, type: string) {
    const query = event.query.toLowerCase();
    let options: IComboBase[] = [];
    switch (type) {
      case 'company':
        options = this.companies;
        break;
      case 'month':
        options = this.months;
        break;
      case 'year':
        options = this.years;
        break;
      case 'typeProofPayment':
        options = this.typeProofPayments;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
    this.filteredOptions = options.filter((option) =>
      option.text.toLowerCase().includes(query)
    );
  }

  // Manejar selección
  onSelect(event: any, type: string) {
    switch (type) {
      case 'company':
        this.selectedCompany = event.value ?? null;
        break;
      case 'month':
        this.selectedMonth = event.value ?? null;
        break;
      case 'year':
        this.selectedYear = event.value ?? null;
        break;
      case 'typeProofPayment':
        this.selectedTypeProofPayment = event.value ?? null;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
  }

  onChangeInput(event: any, type: string) {
    switch (type) {
      case 'company':
        this.selectedCompany = null;
        break;
      case 'month':
        this.selectedMonth = null;
        break;
      case 'year':
        this.selectedYear = null;
        break;
      case 'typeProofPayment':
        this.selectedTypeProofPayment = null;
        break;
      default:
        console.error('Tipo no reconocido:', type);
    }
  }

  isFormValid(): boolean {
    return (
      this.selectedCompany !== null &&
      this.selectedMonth !== null &&
      this.selectedYear !== null &&
      this.selectedTypeProofPayment !== null &&
      this.selectedFileNames.length > 0
    );
  }

  removeFile(index: number) {
    if (index >= 0 && index < this.currentFiles.length) {
      this.currentFiles.splice(index, 1);
      this.selectedFileNames.splice(index, 1);
    }
  }

  backStep() {
    if (this.currentStep === 1) {
      this.router.navigate(['/dashboard/payment-slips']);
    } else {
      this.currentStep--;
    }
  }

  nextStep() {
    const maxStep = 2;

    if (this.currentStep === 1) {
      this.isLoading = true;

      const request = {
        companyId: this.selectedCompany?.key,
        month: this.selectedMonth?.key,
        year: this.selectedYear?.key,
        typeProofPayment: this.selectedTypeProofPayment?.key,
        files: this.currentFiles,
        userAdminId: this.userData.employeeId,
      };

      const formData = new FormData();

      const appendIfDefined = (key: string, value: any) => {
        if (value !== undefined && value !== null) {
          formData.append(key, value);
        }
      };

      appendIfDefined('companyId', request.companyId);
      appendIfDefined('periodMonth', request.month);
      appendIfDefined('periodYear', request.year);
      appendIfDefined('typeVoucherId', request.typeProofPayment);
      appendIfDefined('userId', request.userAdminId);

      if (this.currentFiles.length > 0) {
        this.currentFiles.forEach((file, index) => {
          formData.append('files', file, file.name);
          formData.append('fileName',file.name );
        });
      }

      this.paymentSlipService.postReadPaymentSlipXml(formData).subscribe({
        next: (response) => {
          this.isLoading = false;
          this.dataSource = response.map((item: any, index: number) => ({
            ...item,
            item: index + 1,
            moneda: 'PEN',
          }));
          console.log('Datos recibidos:', this.dataSource);

          if (this.currentStep < maxStep) {
            this.currentStep++;
          }
        },
        error: (err) => {
          this.isLoading = false;
          console.error('Error al procesar los archivos XML:', err);
          const ref = this.dialogService.open(ModalRejectComponent, {
            header: '',
            data: {
              text: err.error.message,
              title: '¡Upss!',
              showButton: true,
              buttonText: 'Entendido',
            },
          });
        },
      });
    } else if (this.currentStep === 2) {
      const ref = this.dialogService.open(ModalConfirmationComponent, {
        header: '',
        data: {
          title: '¿Estás seguro de registrar?',
          description: 'Registrarás varios documentos de pago.',
          showButton: true,
          buttonText: 'Confirmar',

          buttonAction: () => {
            const loadingRef = this.dialogService.open(ModalLoadingComponent, {
              closable: false,
            });
            
            const payload = this.dataSource.map((item) => ({
              userId: this.userData.employeeId,
              cabecera: item.cabecera,
              ingresos: item.ingresos,
              descuentos: item.descuentos,
              aportesTrabajador: item.aportesTrabajador,
              aportesEmpleador: item.aportesEmpleador,
              fileName: item.fileName,
            }));

            console.log('payload ', payload);

            // Llamar al servicio con JSON directamente
            this.paymentSlipService.postRegisterBulkLoad(payload).subscribe({
              next: (response) => {
                loadingRef.close();
                const ref = this.dialogService.open(ModalSuccessComponent, {
                  header: '',
                  data: {
                    text: 'Tu registro se ha realizado con éxito.',
                    title: '¡Éxito!',
                    icon: 'pi pi-check-circle',
                    showButton: true,
                    buttonText: 'Entendido',
                  },
                });
                this.goBack();
              },
              error: (err) => {
                loadingRef.close();
                console.error(
                  'Error al registrar los documentos de pago:',
                  err
                );
                this.dialogService.open(ModalRejectComponent, {
                  header: '',
                  data: {
                    text: err.error.message,
                    title: '¡Upss!',
                    showButton: true,
                    buttonText: 'Entendido',
                  },
                });
              },
            });
          },
        },
      });
    }
  }

  get nextButtonLabel(): string {
    return this.currentStep === 1 ? 'Siguiente' : 'Procesar boletas';
  }

  goBack() {
    this.router.navigate(['/dashboard/payment-slips']);
  }
}
